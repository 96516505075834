import React from 'react';
import PropTypes from 'prop-types';
import StoryblokComponents from '../storyblok/components';

// **
// * This page grabs HomeTwo which handles rendering the page content
// * You probably don't need to change this page
// **

const PageTemplate = ({ pageContext }) => {
  const { story } = pageContext;

  console.log(story.slug);

  const items = React.createElement(
    StoryblokComponents(story.field_component),
    {
      key: story._uid,
      story: JSON.parse(story.content),
      type: story.field_component,
      name: story.name,
      parentId: story.parent_id,
      slug: story.slug,
      fullSlug: story.full_slug,
    }
  );

  return (
    <>
      {items}

      <form style={{ display: 'none' }} name="contact_form" data-netlify="true">
        <input type="hidden" name="form-name" value="contact_form" />
        <div className="form-group">
          <div className="form-control">
            Name
            <input type="text" name="name" />
          </div>
          <div className="form-control">
            email
            <input type="text" name="email" />
          </div>
          <div className="form-control">
            phone
            <input type="text" name="phone" />
          </div>
          <div className="form-control">
            subject
            <input type="text" name="subject" />
          </div>
          <div className="form-control form-control-full">
            message
            <textarea name="message" />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageTemplate;

//

PageTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
